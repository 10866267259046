import { $fetch, $post } from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/report/summaries`, params);
}

export function stage(id) {
  return $post(`customer/report/summary/stage`, { summaryId: id });
}

export function unstage(id) {
  return $post(`customer/report/summary/unstage`, { summaryId: id });
}
