<template>
  <!-- prettier-ignore -->
  <index-content :breadcrumb-items="[$t(`menu.customer.customer_report_summary`)]">
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <n-field-enum v-model="condition.type" enum-name="CustomerReportType" label="type" all-option />
      <n-field-date v-model="condition.from" label="from" />
      <n-field-date v-model="condition.to" label="to" />
      <n-field-enum v-model="condition.issueStatus" enum-name="IssueStatus" label="issueStatus" all-option />
      <n-field-enum v-model="condition.stageStatus" enum-name="StageStatus" label="stageStatus" all-option />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th>{{ $t('label.type') }}</th>
            <th>{{ $t('label.issueDate') }}</th>
            <th>{{ $t('label.issueStatus') }}</th>
            <th>{{ $t('label.issueTime') }}</th>
            <th>{{ $t('label.stageStatus') }}</th>
            <th>{{ $t('label.stageTime') }}</th>
            <th v-rw v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td v-enums:CustomerReportType="row.reportType"></td>
            <td>{{ row.issueDate }}</td>
            <td v-enums:IssueStatus="row.issueStatus"></td>
            <td>{{ row.issueTime2 | datetime }}</td>
            <td v-enums:StageStatus="row.stageStatus"></td>
            <td>{{ row.stageTime2 | datetime }}</td>
            <td v-rw class="n-button-box">
              <nb-confirm custom-icon="fa fa-edit" text="stage" :disabled="cannotStage(row)" @on-ok="doStage(row.id)" />
              <nb-confirm custom-icon="fa fa-edit" text="unstage" :disabled="cannotUnstage(row)" @on-ok="doUnstage(row.id)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import CustomerView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import { fetch, stage, unstage } from '@/api/customer/customer-customer-report-summary';

export default CustomerView.extend({
  name: 'CustomerCustomerReportSummary',
  mixins: [PaginateMixin],
  data() {
    return {
      condition: {
        companyId: null,
        type: 0,
        from: this.prevTradingDate,
        to: this.prevTradingDate,
        issueStatus: 0,
        stageStatus: 0,
      },
    };
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },
    parse(rows) {
      this.records = rows.records;
    },
    cannotStage(model) {
      let issueStatus = model.issueStatus;
      let stageStatus = model.stageStatus;
      return (
        issueStatus !== this.$enums.IssueStatus.SUCCESS ||
        (stageStatus !== this.$enums.StageStatus.PENDING &&
          stageStatus !== this.$enums.StageStatus.UNSTAGED &&
          stageStatus !== this.$enums.StageStatus.FAILURE)
      );
    },
    cannotUnstage(model) {
      let issueStatus = model.issueStatus;
      let stageStatus = model.stageStatus;
      return (
        issueStatus !== this.$enums.IssueStatus.SUCCESS ||
        (stageStatus !== this.$enums.StageStatus.STAGED && stageStatus !== this.$enums.StageStatus.FAILURE)
      );
    },
    doStage(id) {
      return stage(id);
    },
    doUnstage(id) {
      return unstage(id);
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  th.rw-action {
    width: 10rem;
  }
}
</style>
